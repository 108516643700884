import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  addNewOwner,
  deleteAPropertyRecords,
  deleteSubOwner,
  getPropertyById,
  updatePropertyOwner,
} from "../api";

const OwnerForm = (children) => {
  const type = children.type;
  const { ownerId } = useParams();
  const initialOwner = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  };
  const [form, setForm] = useState({});
  const [propertyOwner, setPropertyOwner] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    remarks: "",
  });

  const [subOwners, setSubOwners] = useState([]);

  const handleAddOwner = () => {
    subOwners.push(initialOwner);
    //console.log(subOwners);
    setSubOwners([...subOwners]);
  };

  const handleRemoveOwner = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((res) => {
      if (res.isConfirmed) {
        deleteSubOwner(id).then((res) => {
          Swal.fire("Deleted!", res.data.message, "success").then((r) => {
            //console.log(r);
            if (r.isConfirmed || r.dismiss === "backdrop") {
              window.location.reload();
            }
          });
        });
      }
    });
  };

  const handlePropertyChange = (e) => {
    setPropertyOwner({ ...propertyOwner, [e.target.name]: e.target.value });
  };

  const handleOwnerChange = (e, i) => {
    const name = e.target.name;
    const val = e.target.value;

    switch (name) {
      case "firstName":
        subOwners[i].firstName = val;
        break;
      case "lastName":
        subOwners[i].lastName = val;
        break;
      case "phone":
        subOwners[i].phone = val;
        break;
      case "email":
        subOwners[i].email = val;
        break;
    }

    setSubOwners([...subOwners]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.propertyOwner = propertyOwner;
    form.subOwners = subOwners;
    //console.log(form);

    if (type === "add") {
      addNewOwner(form)
        .then((res) => {
          const success = res.data.success;
          if (success) {
            Swal.fire("Success", "New owner successfully added!", "success");
          }
        })
        .catch((e) => {
          Swal.fire("Error", e.message, "error");
        });
    } else {
      updatePropertyOwner(form, ownerId)
        .then((res) => {
          //console.log(res);
          if (res.data.success) {
            Swal.fire("Success", res.data.message, "success");
          } else {
            Swal.fire("Error", res.data.message, "error");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleCancel = () => {
    window.location.href = "/owners";
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((res) => {
      if (res.isConfirmed) {
        deleteAPropertyRecords(ownerId).then((res) => {
          //console.log(res);
          Swal.fire("Deleted!", res.data.message, "success").then((r) => {
            console.log(r);
            if (r.isConfirmed || r.dismiss === "backdrop") {
              window.location.href = "/owners";
            }
          });
        });
      }
    });
  };

  useEffect(() => {
    if (type === "update") {
      getPropertyById(ownerId)
        .then((res) => {
          //console.log(res.data);
          if (res.data) {
            setPropertyOwner({
              ...propertyOwner,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              phone: res.data.phone,
              email: res.data.email,
              address: res.data.address,
              city: res.data.city,
              state: res.data.state,
              zip: res.data.zip,
              remarks: res.data.remarks,
            });
            setSubOwners(res.data.SubOwners);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  return (
    <div>
      <form
        className="gap-4 p-10 shadow rounded-md bg-white"
        onSubmit={handleSubmit}
      >
        {/* Property info */}

        <div
          className={`grid gap-10 ${
            subOwners.length != 0 ? "md:grid-cols-2" : "md:grid-cols-1"
          }`}
        >
          <div>
            <h3 className="text-lg mb-5 font-bold">Property Owner</h3>
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First Name
              </label>
              <div>
                <input
                  value={propertyOwner.firstName}
                  onChange={handlePropertyChange}
                  required
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last Name
              </label>
              <div>
                <input
                  value={propertyOwner.lastName}
                  onChange={handlePropertyChange}
                  required
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Phone
              </label>
              <div>
                <input
                  value={propertyOwner.phone}
                  onChange={handlePropertyChange}
                  type="text"
                  name="phone"
                  id="phone"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div>
                <input
                  value={propertyOwner.email}
                  onChange={handlePropertyChange}
                  type="email"
                  name="email"
                  id="email"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Address
              </label>
              <div>
                <input
                  value={propertyOwner.address}
                  onChange={handlePropertyChange}
                  required
                  type="text"
                  name="address"
                  id="address"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                City
              </label>
              <div>
                <input
                  value={propertyOwner.city}
                  onChange={handlePropertyChange}
                  required
                  type="text"
                  name="city"
                  id="city"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="state"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                State
              </label>
              <div>
                <input
                  value={propertyOwner.state}
                  onChange={handlePropertyChange}
                  required
                  type="text"
                  name="state"
                  id="state"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="zip"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Zip
              </label>
              <div>
                <input
                  value={propertyOwner.zip}
                  onChange={handlePropertyChange}
                  required
                  type="text"
                  name="zip"
                  id="zip"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="remarks"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Remarks
              </label>
              <div>
                <input
                  value={propertyOwner.remarks}
                  onChange={handlePropertyChange}
                  type="text"
                  name="remarks"
                  id="remarks"
                  className="px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          {/* Owner */}
          <div>
            {subOwners.map((owner, i) => {
              return (
                <div key={i}>
                  <h3 className="text-lg font-bold">Sub Owner {i + 1}</h3>
                  <div className="mt-5">
                    <label
                      htmlFor={`firstName` + i}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First Name
                    </label>
                    <div>
                      <input
                        value={owner.firstName}
                        onChange={(e) => handleOwnerChange(e, i)}
                        required
                        type="text"
                        name="firstName"
                        id={`firstName` + i}
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor={`lastName` + i}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last Name
                    </label>
                    <div>
                      <input
                        value={owner.lastName}
                        onChange={(e) => handleOwnerChange(e, i)}
                        type="text"
                        name="lastName"
                        id={`lastName` + i}
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor={`phone` + i}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone
                    </label>
                    <div>
                      <input
                        value={owner.phone}
                        onChange={(e) => handleOwnerChange(e, i)}
                        type="text"
                        name="phone"
                        id={`phone` + i}
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor={`email` + i}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div>
                      <input
                        value={owner.email}
                        onChange={(e) => handleOwnerChange(e, i)}
                        type="text"
                        name="email"
                        id={`email` + i}
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {owner.id ? (
                    <div
                      onClick={() => handleRemoveOwner(owner.id)}
                      className="mb-5 underline text-sm text-red-500 hover:text-red-600 cursor-pointer"
                    >
                      Remove
                    </div>
                  ) : null}
                </div>
              );
            })}

            <div className="mt-5">
              <button
                onClick={handleAddOwner}
                type="button"
                className="mr-3 underline text-blue-500 hover:text-blue-600 font-semibold"
              >
                Add owner
              </button>
            </div>
          </div>
        </div>

        <div className="mt-3 flex justify-end">
          {type === "update" ? (
            <button
              onClick={handleDelete}
              type="button"
              className="my-auto underline text-red-500 mr-10"
            >
              Delete records
            </button>
          ) : null}

          <button
            type="submit"
            className="capitalize w-40 mr-2 rounded-md bg-indigo-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {type}
          </button>
          <button
            onClick={handleCancel}
            type="button"
            className="w-40 mr-2 rounded-md bg-gray-500 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default OwnerForm;
