import Sidebar from "./components/Sidebar";
import AddOwner from "./pages/AddOwner";
import Owners from "./pages/Owners";

function App() {
  return (
    <div>
      <Owners />
    </div>
  );
}

export default App;
