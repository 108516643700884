import Layout from "../components/Layout";
import OwnerForm from "../components/OwnerForm";

const AddOwner = () => {
  return (
    <Layout>
      <h1 className="text-2xl font-semibold mb-3">Add New Property Owner</h1>
      <OwnerForm type="add" />
    </Layout>
  );
};

export default AddOwner;
