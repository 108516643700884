import { useEffect, useState } from "react";
import { getPropertyOwners, search } from "../api";
import Layout from "../components/Layout";
import { Streets } from "../data";

const columns = ["First Name", "Last Name", "Address", "City", "State", "Zip"];

const perPage = [
  { value: "10", name: "Show 10" },
  { value: "20", name: "Show 20" },
  { value: "30", name: "Show 30" },
  { value: "40", name: "Show 40" },
  { value: "50", name: "Show 50" },
  { value: "100", name: "Show 100" },
];

export default function Owners() {
  const urlParams = new URLSearchParams(window.location.search);
  const streetParam = urlParams.get("street") ? urlParams.get("street") : "";
  const [propertyOwners, setpropertyOwners] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [queryData, setQueryData] = useState({
    page: 0,
    limit: 20,
    street: "",
    state: "",
    city: "",
    total: 0,
    pages: 0,
  });

  const handlePrevNext = (t) => {
    if (t === "next" && queryData.page < queryData.pages - 1) {
      setQueryData({ ...queryData, page: (queryData.page += 1) });
      if (isSearch) {
        searchData();
      } else {
        getOwners();
      }
    } else if (t === "prev" && queryData.page !== 0) {
      setQueryData({ ...queryData, page: (queryData.page -= 1) });
      if (isSearch) {
        searchData();
      } else {
        getOwners();
      }
    }
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      const ser = e.target.value;

      ser === "" ? setIsSearch(false) : setIsSearch(true);
      queryData.page = 0;
      setQueryData({ ...queryData });
      ser === "" ? getOwners() : searchData(ser);
    }
  };

  const handleFilter = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    switch (id) {
      case "street":
        queryData.street = value;
        queryData.page = 0;
        break;
      case "state":
        queryData.state = value;
        queryData.page = 0;
        break;
      case "city":
        queryData.city = value;
        queryData.page = 0;
        break;
      case "limit":
        queryData.limit = value;
        queryData.page = 0;
        break;
    }

    setQueryData({ ...queryData });
    getOwners();
  };

  const handleClearFilters = () => {
    document.getElementById("serIn").value = "";
    document.getElementById("street").options[0].selected = "selected";
    document.getElementById("state").options[0].selected = "selected";
    document.getElementById("city").options[0].selected = "selected";
    //document.getElementById("limit").options[1].selected = "selected";

    queryData.street = "";
    queryData.state = "";
    queryData.city = "";
    queryData.search = "";
    queryData.limit = "20";
    setQueryData({ ...queryData });

    getOwners();
  };

  useEffect(() => {
    if (streetParam !== "" && streetParam !== null) {
      queryData.street = streetParam;
      document.getElementById("street").value = streetParam;
    }
    getOwners();
  }, []);

  function searchData(dSearch) {
    search(dSearch, queryData.limit, queryData.page).then((d) => {
      //console.log(d);
      const t = d.data.count;

      setpropertyOwners(d.data.rows);
      setQueryData({
        ...queryData,
        total: t,
        pages: Math.ceil(t / queryData.limit),
      });
    });
  }

  function getOwners() {
    setIsSearch(false);
    getPropertyOwners(
      queryData.limit,
      queryData.page,
      queryData.street,
      queryData.state,
      queryData.city
    ).then((d) => {
      console.log(d);
      const t = d.data.count;
      setpropertyOwners(d.data.rows);
      setQueryData({
        ...queryData,
        total: t,
        pages: Math.ceil(t / queryData.limit),
      });
    });
  }

  function sortTable(n) {
    let table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchCount = 0;
    table = document.getElementById("myTable");
    switching = true;
    dir = "asc";

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];

        if (dir === "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchCount++;
      } else {
        if (switchCount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  return (
    <Layout>
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={() => {
                window.location.href = "/newOwner";
              }}
              type="button"
              className="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add owner
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="mb-2 flex">
                <div className="mr-5 flex h-[36px]">
                  <input
                    id="serIn"
                    onKeyDown={handleSearch}
                    className="w-[13rem] px-1.5 py-1.5 rounded-md placeholder:text-xs text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Search for name, address, and etc."
                  ></input>
                </div>

                <div className="mr-5">
                  <select
                    id="street"
                    className="px-3 py-1.5 rounded-md shadow w-[12rem]"
                    onChange={(e) => handleFilter(e)}
                  >
                    <option value="">Select Street</option>
                    {Streets.map((s, i) => (
                      <option key={i}>{s}</option>
                    ))}
                  </select>
                </div>

                <div className="mr-5">
                  <select
                    id="limit"
                    className="px-3 py-1.5 rounded-md shadow w-[7rem]"
                    //value={queryData.limit}
                    onChange={(e) => handleFilter(e)}
                    value={queryData.limit}
                  >
                    {perPage.map((p, i) => (
                      <option key={i} value={p.value}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mr-2">
                  <button
                    className="px-3 py-1 underline text-gray-800 hover:text-gray-900"
                    type="button"
                    onClick={handleClearFilters}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
              <h3>
                Total results:{" "}
                <span className="font-semibold">{queryData.total}</span>
              </h3>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table
                  id="myTable"
                  className="min-w-full divide-y divide-gray-300"
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {columns.map((c, i) => (
                        <th
                          key={i}
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {c}
                          <button onClick={() => sortTable(i)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-1 w-3 h-3"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 320 512"
                            >
                              <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                            </svg>
                          </button>
                        </th>
                      ))}
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {propertyOwners.map((property, i) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                          {property.firstName}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                          {property.lastName}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                          {property.address}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                          {property.city}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 uppercase">
                          {property.state}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                          {property.zip}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a
                            href={"/updateOwner/" + property.id}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                            <span className="sr-only"></span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <nav
              className="flex items-center justify-between border-gray-200 px-4 sm:px-8"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  <span>
                    Page {queryData.page + 1} of {queryData.pages}
                  </span>
                </p>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end">
                <button
                  onClick={() => handlePrevNext("prev")}
                  type="button"
                  className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </button>
                <button
                  onClick={() => handlePrevNext("next")}
                  type="button"
                  className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </Layout>
  );
}
