import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { updateUser } from "../api";
import Layout from "../components/Layout";
import { AuthContext } from "../context";

const Settings = () => {
  const { user, onLogout } = useContext(AuthContext);
  const [form, setForm] = useState({ name: "", username: "", password: "" });

  function showPassword(e) {
    if (e.target.checked) {
      document.getElementById("password").type = "text";
    } else {
      document.getElementById("password").type = "password";
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser(user.id, form).then((res) => {
      if (res.data.success) {
        Swal.fire("Success", res.data.message, "success").then((res) => {
          if (res.isConfirmed || res.dismiss === "backdrop") {
            onLogout();
          }
        });
      } else {
        Swal.fire("Error", res.data.message, "error");
      }
    });
  };

  useEffect(() => {
    setForm({ ...form, name: user.name, username: user.username });
  }, []);

  return (
    <Layout>
      <div>
        <div>
          <h1 className="mb-4 text-2xl font-bold text-gray-900">
            Security Settings
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="md:w-1/3 grid md:grid-cols-1 bg-white p-4 shadow rounded-md">
              <div className="mb-4">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  value={form.name}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      name: e.target.value,
                    })
                  }
                  required
                  id="name"
                  type="text"
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ></input>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Username
                </label>
                <input
                  value={form.username}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      username: e.target.value,
                    })
                  }
                  required
                  id="username"
                  type="text"
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ></input>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  value={form.password}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      password: e.target.value,
                    })
                  }
                  required
                  id="password"
                  type="password"
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ></input>
                <div className="mt-2 flex items-center">
                  <input
                    onChange={(e) => showPassword(e)}
                    id="showPass"
                    type="checkbox"
                    className="mr-1"
                  ></input>
                  <label htmlFor="showPass" className="text-sm">
                    Show password
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="mr-4 md:w-30 tracking-widest items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
