import axios from "axios";

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getPropertyOwners = (limit, page, street, state, city) =>
  apiInstance.get(
    "/getPropertyOwners?limit=" +
      limit +
      "&page=" +
      page +
      "&street=" +
      street +
      "&state=" +
      state +
      "&city=" +
      city
  );

export const search = (search, limit, page) =>
  apiInstance.get("/search?q=" + search + "&limit=" + limit + "&page=" + page);

export const getPropertyById = (id) =>
  apiInstance.get("/getPropertyById/" + id);

export const addNewOwner = (data) =>
  apiInstance.post("/addNewPropertyOwner", data);

export const updatePropertyOwner = (data, id) =>
  apiInstance.put("/updatePropertyOwner/" + id, data);

export const deleteSubOwner = (id) =>
  apiInstance.delete("/deleteSubOwner/" + id);

export const deleteAPropertyRecords = (id) =>
  apiInstance.delete("/deleteAPropertyRecords/" + id);

export const getUserFromToken = ({ token }) =>
  apiInstance.post("/getUserFromToken", { token });

export const login = (username, password) =>
  apiInstance.post("/login", { username, password });

export const updateUser = (id, data) =>
  apiInstance.put("/updateUser/" + id, data);

export const getDatabaseSummary = () => apiInstance.get("/getDatabaseSummary");

export const getOwnersPerStreet = (street) =>
  apiInstance.get("/getOwnersPerStreet/" + street);
