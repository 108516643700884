export const Streets = [
  "BERMUDA CIRCLE",
  "CABIN CREEK LN",
  "CHALLENGE CLUB DR",
  "CROSS WAY LN",
  "CYPRESS TRAIL LN",
  "DEER GRASS LN",
  "DUFFERS WALK LN",
  "EAGLE POINT LN",
  "HUNTERS WALK LN",
  "LAKE POINT LN",
  "PINEHURST LN",
  "PLANTERS WALK LN",
  "SAND TRAP LN",
  "STILLWATER LN",
  "TIMBERLAKE DR",
  "WATERS EDGE LN",
];

export const States = [
  {
    abriv: "FL",
    name: "Florida",
  },
  { abriv: "NC", name: "North Carolina" },
];

export const Cities = [
  "CLAYTON",
  "CLINTON",
  "DUNN",
  "FAISON",
  "GOLDSBORO",
  "NEWTON GROVE",
  "PEMBROKE PINE",
  "RALEIGH",
  "SMITHFIELD",
  "WARSAW",
];
