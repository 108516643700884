import { createBrowserRouter } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import AddOwner from "./pages/AddOwner";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Owners from "./pages/Owners";
import Settings from "./pages/Settings";
import UpdateOwner from "./pages/UpdateOwner";
import Summary from "./pages/Summary";

const routes = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        element: <RequireAuth children={<Owners />} />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "/summary",
        element: <RequireAuth children={<Summary />} />,
      },
      {
        path: "/owners",
        element: <RequireAuth children={<Owners />} />,
      },
      {
        path: "/newOwner",
        element: <RequireAuth children={<AddOwner />} />,
      },
      {
        path: "/settings",
        element: <RequireAuth children={<Settings />} />,
      },
      {
        path: "/updateOwner/:ownerId",
        element: <RequireAuth children={<UpdateOwner />} />,
      },
    ],
  },
]);

export default routes;
