import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { login } from "../api";
import { AuthContext } from "../context";

const Login = () => {
  const [form, setForm] = useState({ username: "", password: "" });
  const { user, onLogin } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(form.username, form.password).then((res) => {
      if (!res.data.success) {
        return Swal.fire("Error", res.data.message, "error");
      }
      onLogin(res.data.token);
    });
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4">
      <div className="w-full max-w-md space-y-8 bg-white shadow py-10 px-5 mt-10">
        <h2 className="text-3xl text-center font-bold text-gray-900">
          Sign in to your account
        </h2>
        <div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="username" className="sr-only">
                  Username
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  onChange={(e) =>
                    setForm({ ...form, username: e.target.value })
                  }
                  className="relative block w-full rounded-t-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Username"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  className="relative block w-full rounded-b-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="">
              <button className="group relative w-full bg-indigo-600 rounded-md py-2 text-white font-bold">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
